<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-3">
          <label class="d-block" for="">Site</label>
          <multiselect
            v-model="searchForm.camps"
            :searchable="true"
            track-by="id"
            open-direction="bottom"
            label="name"
            :options="campsList"
            style="width: 100% !important;"
            placeholder=""
            :allow-empty="true"
            :multiple="true"
          >
            <template slot="singleRoom" slot-scope="{ camp }">{{
              camp.name
            }}</template>
          </multiselect>
        </div>

        <!-- <div class="col-md-2">
          <label class="d-block" for="">N° Réservations</label>
          <input
            id="inlineFormInputName2"
            type="text"
            v-model="searchForm.bookingNo"
            placeholder=""
            class="mb-3 form-control"
          />
        </div> -->

        <div class="col-md-2">
          <label class="d-block" for="">Bénéficiaire</label>
          <input
            id="inlineFormInputName2"
            type="text"
            v-model="searchForm.occupant"
            placeholder=""
            class="mb-3 form-control"
          />
        </div>
        <div class="col-md-1">
          <label class="d-block" for="">Chambre</label>
          <input
            id="inlineFormInputName2"
            type="text"
            v-model="searchForm.roomNo"
            placeholder=""
            class="mb-3 form-control"
          />
        </div>
        <div class="col">
          <label class="d-block" for="">Date d'arrivé</label>
          <input
            id="inlineFormInputName2"
            type="date"
            v-model="searchForm.dateIn"
            placeholder="Bénéficiaire"
            class="mb-3 form-control"
          />
        </div>

        <div class="col">
          <label class="d-block" for="">Date de départ</label>
          <input
            id="inlineFormInputName2"
            type="date"
            v-model="searchForm.dateOut"
            placeholder="Bénéficiaire"
            class="mb-3 form-control"
          />
        </div>
        <div class="col">
          <label class="d-block" for="">Status</label>
          <select class="form-control" v-model="searchForm.status">
            <option value="draft">Brouillon</option>
            <option value="approved">Approuvé</option>
            <!-- <option value="close"></option> -->
            <option value="checkin">Check In</option>
            <option value="checkout">Check Out</option>
          </select>
        </div>
        <div class="col-md-2">
          <button
            @click="performBookingSearch"
            type="button"
            style="inline-block"
            class="btn w-md btn-primary mt-4"
          >
            Rechercher
          </button>
          <button style="inline-block" type="button" class="btn btn-warning mt-4 ml-2" @click="resetSearchForm">Vider</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { fetchLifebasesApi } from "@/api/common";

export default {
  import: {fetchLifebasesApi},
  components: { Multiselect },
  data() {
    return {
      campsList: [],
      searchForm: {
        camps: [],
        bookingNo: "",
        occupant: "",
        dateIn: "",
        dateOut: "",
        roomNo: "",
        status:""
      },
    };
  },
  mounted() {
    this._fetchCampsList();
  },
  methods: {
    _fetchCampsList() {
      fetchLifebasesApi()
        .then((res) => (this.campsList = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
      // bases
    },
    performBookingSearch() {
      if (
        this.searchForm.camps == "" &&
        this.searchForm.bookingNo == "" &&
        this.searchForm.occupant == "" &&
        this.searchForm.dateIn == "" &&
        this.searchForm.roomNo == "" &&
        this.searchForm.dateOut == "" &&
        this.searchForm.status == ""
      ) {
        this.$toast.error("Veuillez remplir au moin un champ.");
      } else {
        this.$emit("performBookingSearch", this.searchForm);
      }
    },

    resetSearchForm() {
      this.searchForm.camps = "";
        this.searchForm.bookingNo = "";
        this.searchForm.occupant = "";
        this.searchForm.dateIn = "";
        this.searchForm.roomNo = "";
        this.searchForm.dateOut = "";
        this.searchForm.status == "";
        this.$emit("resetSearchForm", true);
    },
  },
};
</script>
