var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fieldset", [
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c(
            "b-tabs",
            { attrs: { "content-class": "p-3 text-muted" } },
            [
              _c(
                "b-tab",
                {
                  staticClass: "border-0",
                  attrs: { active: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            { staticClass: "d-inline-block d-sm-none" },
                            [_c("i", { staticClass: "fas fa-home" })]
                          ),
                          _c(
                            "span",
                            { staticClass: "d-none d-sm-inline-block" },
                            [_vm._v("Informations")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-lg-12" },
                        [
                          _vm.bookingForm.ref
                            ? _c(
                                "b-alert",
                                { attrs: { show: "", variant: "info" } },
                                [
                                  _c("i", {
                                    staticClass:
                                      "mdi mdi-alert-circle-outline mr-2"
                                  }),
                                  _vm._v("Détails de réservation N°: "),
                                  _c("b", [_vm._v(_vm._s(_vm.bookingForm.ref))])
                                ]
                              )
                            : _vm._e(),
                          _vm.bookingForm.ref &&
                          (_vm.bookingForm.statusPlain == "approved" ||
                            _vm.bookingForm.statusPlain == "checkin")
                            ? _c(
                                "div",
                                { staticClass: "row singleBookingActions" },
                                [
                                  _vm.$can("cancel_booking")
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.handleBookingCancelClick(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-close"
                                          }),
                                          _vm._v(" Annuler ")
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.showCheckinButton
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.showCheckinModal(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-check"
                                          }),
                                          _vm._v(" Check In ")
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.showCheckoutButton
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-warning",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.showCheckOutModal(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-window-close"
                                          }),
                                          _vm._v(" Check Out ")
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-info",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.events.showProlongationModal = true
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-calendar-plus"
                                      }),
                                      _vm._v(" Ajouter Prolongement ")
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.events.showRelocationModal = true
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "mdi mdi-repeat"
                                      }),
                                      _vm._v(" Ajouter Délogement ")
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.bookingForm.ref &&
                          _vm.bookingForm.statusPlain == "draft"
                            ? _c(
                                "div",
                                { staticClass: "row singleBookingActions" },
                                [
                                  _vm.$can("approve_booking")
                                    ? _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.approvalModal",
                                              modifiers: { approvalModal: true }
                                            }
                                          ],
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.showApprobationModal(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-check"
                                          }),
                                          _vm._v(" Approuver ")
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.$can("reject_booking")
                                    ? _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.rejectionModal",
                                              modifiers: {
                                                rejectionModal: true
                                              }
                                            }
                                          ],
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.showRejectionModal(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-window-close"
                                          }),
                                          _vm._v(" Rejeter ")
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "fieldset",
                            { attrs: { disabled: _vm.formDisabled } },
                            [
                              _c(
                                "form",
                                {
                                  staticClass: "needs-validation",
                                  attrs: { disabled: "" },
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.formSubmit($event)
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-lg-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "formrow-inputCity"
                                              }
                                            },
                                            [_vm._v("Type de bénéficiaire *")]
                                          ),
                                          _c("multiselect", {
                                            class: {
                                              "is-invalid":
                                                _vm.submitted &&
                                                _vm.$v.bookingForm
                                                  .beneficiaryType.$error,
                                              isDisabledDropdown:
                                                _vm.disableImportantFields ||
                                                _vm.disableBenefType
                                            },
                                            attrs: {
                                              searchable: true,
                                              "track-by": "id",
                                              "open-direction": "bottom",
                                              label: "name",
                                              options: _vm.beneficiaryTypes,
                                              placeholder: "",
                                              "allow-empty": false
                                            },
                                            on: {
                                              input: _vm.onBeneficiaryTypeChange
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "singleRoom",
                                                fn: function(ref) {
                                                  var type = ref.type
                                                  return [
                                                    _vm._v(_vm._s(type.name))
                                                  ]
                                                }
                                              }
                                            ]),
                                            model: {
                                              value:
                                                _vm.bookingForm.beneficiaryType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.bookingForm,
                                                  "beneficiaryType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "bookingForm.beneficiaryType"
                                            }
                                          }),
                                          _vm.submitted &&
                                          _vm.$v.bookingForm.beneficiaryType
                                            .$error
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback"
                                                },
                                                [
                                                  !_vm.$v.bookingForm
                                                    .beneficiaryType.required
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "Champs Obligatoire."
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm.bookingForm.beneficiaryType.id ==
                                    "sh_employee"
                                      ? _c("div", { staticClass: "col-lg-3" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "formrow-inputCity"
                                                  }
                                                },
                                                [_vm._v("Structure *")]
                                              ),
                                              _vm.bookingForm.structName == ""
                                                ? _c("multiselect", {
                                                    class: {
                                                      "is-invalid":
                                                        _vm.submitted &&
                                                        _vm.$v.bookingForm
                                                          .beneficiaryType
                                                          .$error,
                                                      isDisabledDropdown:
                                                        _vm.disableImportantFields
                                                    },
                                                    attrs: {
                                                      searchable: true,
                                                      "track-by": "code",
                                                      "open-direction":
                                                        "bottom",
                                                      label: "name",
                                                      options: _vm.structures,
                                                      placeholder: "",
                                                      "allow-empty": false
                                                    },
                                                    on: {
                                                      input: _vm.onStructChange
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "singleRoom",
                                                          fn: function(ref) {
                                                            var type = ref.type
                                                            return [
                                                              _vm._v(
                                                                _vm._s(
                                                                  type.name
                                                                )
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      890565942
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.bookingForm
                                                          .structId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.bookingForm,
                                                          "structId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "bookingForm.structId"
                                                    }
                                                  })
                                                : _c("input", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      disabled: ""
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.bookingForm
                                                          .structName
                                                    }
                                                  }),
                                              _vm.submitted &&
                                              _vm.$v.bookingForm.beneficiaryType
                                                .$error
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback"
                                                    },
                                                    [
                                                      !_vm.$v.bookingForm
                                                        .beneficiaryType
                                                        .required
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "Champs Obligatoire."
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.bookingForm.beneficiaryType.id ==
                                    "visitor"
                                      ? _c("div", { staticClass: "col-lg-3" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "formrow-inputCity"
                                                  }
                                                },
                                                [_vm._v("Entreprise *")]
                                              ),
                                              !_vm.bookingForm.companyName
                                                ? _c("multiselect", {
                                                    attrs: {
                                                      searchable: true,
                                                      "track-by": "id",
                                                      "open-direction":
                                                        "bottom",
                                                      label: "company_name",
                                                      options: _vm.companies,
                                                      placeholder: "",
                                                      "allow-empty": false
                                                    },
                                                    on: {
                                                      input: _vm.onCompanyChange
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "singleRoom",
                                                          fn: function(ref) {
                                                            var type = ref.type
                                                            return [
                                                              _vm._v(
                                                                _vm._s(
                                                                  type.company_name
                                                                )
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1500164494
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.bookingForm
                                                          .companyId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.bookingForm,
                                                          "companyId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "bookingForm.companyId"
                                                    }
                                                  })
                                                : _c("input", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      disabled: ""
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.bookingForm
                                                          .companyName
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.bookingForm.beneficiaryType.id ==
                                    "supplier_employee"
                                      ? _c("div", { staticClass: "col-lg-3" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "formrow-inputCity"
                                                  }
                                                },
                                                [_vm._v("Prestataire *")]
                                              ),
                                              !_vm.bookingForm.companyName
                                                ? _c("multiselect", {
                                                    attrs: {
                                                      searchable: true,
                                                      "track-by": "id",
                                                      "open-direction":
                                                        "bottom",
                                                      label: "name",
                                                      options: _vm.suppliers,
                                                      placeholder: "",
                                                      "allow-empty": false
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.onSupplierChange
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "singleRoom",
                                                          fn: function(ref) {
                                                            var supplier =
                                                              ref.supplier
                                                            return [
                                                              _vm._v(
                                                                _vm._s(
                                                                  supplier.name
                                                                )
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2760037558
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.bookingForm
                                                          .supplierId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.bookingForm,
                                                          "supplierId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "bookingForm.supplierId"
                                                    }
                                                  })
                                                : _c("input", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      disabled: ""
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.bookingForm
                                                          .companyName
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("div", { staticClass: "col-lg-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "formrow-inputCity"
                                              }
                                            },
                                            [_vm._v("Bénéficiaire *")]
                                          ),
                                          !_vm.events.showEmployeeNameField &&
                                          (_vm.bookingForm.beneficiaryType.id ==
                                            "sh_employee" ||
                                            _vm.bookingForm.beneficiaryType
                                              .id == "supplier_employee" ||
                                            _vm.bookingForm.beneficiaryType
                                              .id == "sh_external_employee")
                                            ? _c("multiselect", {
                                                class: {
                                                  isDisabledDropdown:
                                                    _vm.bookingForm
                                                      .beneficiaryType == "" ||
                                                    _vm.disableImportantFields,
                                                  "is-invalid":
                                                    _vm.submitted &&
                                                    _vm.$v.bookingForm
                                                      .beneficiaryId.$error
                                                },
                                                attrs: {
                                                  searchable: true,
                                                  "track-by": "id",
                                                  label: "empnum_fname",
                                                  options: _vm.beneficiaries,
                                                  placeholder: "",
                                                  "allow-empty": false
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "singleRoom",
                                                      fn: function(ref) {
                                                        var benef = ref.benef
                                                        return [
                                                          _vm._v(
                                                            _vm._s(
                                                              benef.empnum_fname
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1731449857
                                                ),
                                                model: {
                                                  value:
                                                    _vm.bookingForm
                                                      .beneficiaryId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.bookingForm,
                                                      "beneficiaryId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "bookingForm.beneficiaryId"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm.bookingForm.beneficiaryType.id ==
                                          "sh_employee"
                                            ? _c("span", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.bookingForm
                                                        .beneficiaryId.post +
                                                        "/"
                                                    ) +
                                                      " Chambre affectée: " +
                                                      _vm._s(
                                                        _vm.bookingForm
                                                          .beneficiaryId.room_no
                                                          ? _vm.bookingForm
                                                              .beneficiaryId
                                                              .room_no
                                                          : "N/A"
                                                      )
                                                  )
                                                ])
                                              ])
                                            : _vm._e(),
                                          _vm.events.showEmployeeNameField
                                            ? _c("input", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  disabled: "",
                                                  type: "text"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.bookingForm
                                                      .beneficiaryFullName
                                                }
                                              })
                                            : _vm._e(),
                                          !_vm.events.showVisitorNameField &&
                                          _vm.bookingForm.beneficiaryType.id ==
                                            "visitor"
                                            ? _c("multiselect", {
                                                class: {
                                                  isDisabledDropdown:
                                                    _vm.bookingForm
                                                      .beneficiaryType == "" ||
                                                    _vm.disableImportantFields
                                                },
                                                attrs: {
                                                  searchable: true,
                                                  "track-by": "id",
                                                  label: "full_name",
                                                  options: _vm.beneficiaries,
                                                  placeholder: "",
                                                  "allow-empty": false
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "singleRoom",
                                                      fn: function(ref) {
                                                        var benef = ref.benef
                                                        return [
                                                          _vm._v(
                                                            _vm._s(
                                                              benef.full_name
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1841224538
                                                ),
                                                model: {
                                                  value:
                                                    _vm.bookingForm
                                                      .beneficiaryId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.bookingForm,
                                                      "beneficiaryId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "bookingForm.beneficiaryId"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm.events.showVisitorNameField
                                            ? _c("input", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  disabled: "",
                                                  type: "text"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.bookingForm.visitorName
                                                }
                                              })
                                            : _vm._e(),
                                          _vm.submitted &&
                                          _vm.$v.bookingForm.beneficiaryId
                                            .$error
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback"
                                                },
                                                [
                                                  !_vm.$v.bookingForm
                                                    .beneficiaryId.required
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "Champs Obligatoire."
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("div", { staticClass: "col-lg-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "formrow-inputCity"
                                              }
                                            },
                                            [_vm._v("Site *")]
                                          ),
                                          _c("multiselect", {
                                            class: {
                                              isDisabledDropdown:
                                                _vm.disableImportantFields
                                            },
                                            attrs: {
                                              searchable: true,
                                              "track-by": "id",
                                              label: "name",
                                              options: _vm.camps,
                                              placeholder:
                                                "Selectionner le site",
                                              "allow-empty": false
                                            },
                                            on: { input: _vm.onCampChange },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "singleCamp",
                                                fn: function(ref) {
                                                  var camp = ref.camp
                                                  return [
                                                    _vm._v(_vm._s(camp.name))
                                                  ]
                                                }
                                              }
                                            ]),
                                            model: {
                                              value: _vm.bookingForm.campId,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.bookingForm,
                                                  "campId",
                                                  $$v
                                                )
                                              },
                                              expression: "bookingForm.campId"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("div", { staticClass: "col-lg-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "formrow-inputCity"
                                              }
                                            },
                                            [_vm._v("Bloc *")]
                                          ),
                                          _c("multiselect", {
                                            attrs: {
                                              searchable: true,
                                              "track-by": "id",
                                              label: "name",
                                              options: _vm.blocs,
                                              placeholder:
                                                "Sélectionnez un Bloc",
                                              "allow-empty": false
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "singleBloc",
                                                fn: function(ref) {
                                                  var bloc = ref.bloc
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(bloc.name) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ]),
                                            model: {
                                              value: _vm.bookingForm.bloc,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.bookingForm,
                                                  "bloc",
                                                  $$v
                                                )
                                              },
                                              expression: "bookingForm.bloc"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("div", { staticClass: "col-lg-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "formrow-inputCity"
                                              }
                                            },
                                            [_vm._v("Type de chambre *")]
                                          ),
                                          _c("multiselect", {
                                            attrs: {
                                              searchable: true,
                                              "track-by": "id",
                                              label: "designation",
                                              options: _vm.roomsTypes,
                                              placeholder: "Select room type",
                                              "allow-empty": false
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "singleType",
                                                fn: function(ref) {
                                                  var type = ref.type
                                                  return [
                                                    _vm._v(
                                                      _vm._s(type.designation)
                                                    )
                                                  ]
                                                }
                                              }
                                            ]),
                                            model: {
                                              value: _vm.bookingForm.roomType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.bookingForm,
                                                  "roomType",
                                                  $$v
                                                )
                                              },
                                              expression: "bookingForm.roomType"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("div", { staticClass: "col-md-3" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "formrow-password-input"
                                            }
                                          },
                                          [_vm._v("Arrivé *")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.bookingForm.dateIn,
                                              expression: "bookingForm.dateIn"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            "is-invalid":
                                              _vm.submitted &&
                                              _vm.$v.bookingForm.dateIn.$error
                                          },
                                          attrs: { type: "date" },
                                          domProps: {
                                            value: _vm.bookingForm.dateIn
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.bookingForm,
                                                "dateIn",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _vm.submitted &&
                                        _vm.$v.bookingForm.dateIn.$error
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "invalid-feedback"
                                              },
                                              [
                                                !_vm.$v.bookingForm.dateIn
                                                  .required
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Date d'arrivé est obligatoire."
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ]),
                                    _c("div", { staticClass: "col-lg-3" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "formrow-inputCity" }
                                          },
                                          [_vm._v("Départ *")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.bookingForm.dateOut,
                                              expression: "bookingForm.dateOut"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            "is-invalid":
                                              _vm.submitted &&
                                              _vm.$v.bookingForm.dateOut.$error
                                          },
                                          attrs: { type: "date" },
                                          domProps: {
                                            value: _vm.bookingForm.dateOut
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.bookingForm,
                                                "dateOut",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _vm.submitted &&
                                        _vm.$v.bookingForm.dateOut.$error
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "invalid-feedback"
                                              },
                                              [
                                                !_vm.$v.bookingForm.dateOut
                                                  .required
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Date de Départ est obligatoire."
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ]),
                                    _c("div", { staticClass: "col-lg-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "formrow-inputCity"
                                              }
                                            },
                                            [_vm._v("Chambre *")]
                                          ),
                                          _c("multiselect", {
                                            attrs: {
                                              searchable: true,
                                              "track-by": "id",
                                              label: "roomName",
                                              options: _vm.rooms,
                                              placeholder:
                                                "Sélectionnez une chambre",
                                              "allow-empty": false
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "singleRoom",
                                                fn: function(ref) {
                                                  var room = ref.room
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(room.roomName) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ]),
                                            model: {
                                              value: _vm.bookingForm.room,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.bookingForm,
                                                  "room",
                                                  $$v
                                                )
                                              },
                                              expression: "bookingForm.room"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("div", { staticClass: "col-lg-3" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "formrow-inputCity" }
                                          },
                                          [_vm._v("Nuités")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.bookingForm.nights,
                                              expression: "bookingForm.nights"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            readonly: "",
                                            name: "",
                                            id: ""
                                          },
                                          domProps: {
                                            value: _vm.bookingForm.nights
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.bookingForm,
                                                "nights",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-12" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "formrow-inputCity" }
                                          },
                                          [_vm._v("Commentaires *")]
                                        ),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.bookingForm.comments,
                                              expression: "bookingForm.comments"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: { cols: "30", rows: "4" },
                                          domProps: {
                                            value: _vm.bookingForm.comments
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.bookingForm,
                                                "comments",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary float-right",
                                            attrs: { type: "submit" }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-save"
                                            }),
                                            _vm._v(" Enregistrer ")
                                          ]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "btn btn-light btn-label  mr-2 float-right",
                                            attrs: { variant: "danger" },
                                            on: { click: _vm.cancelForm }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-times-circle text-white label-icon"
                                            }),
                                            _vm._v(" Annuler ")
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "Modal",
                        {
                          attrs: {
                            title:
                              "Ajouter un " +
                              _vm.bookingForm.beneficiaryType.name
                          },
                          model: {
                            value: _vm.events.showNewBeneficiaryModal,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.events,
                                "showNewBeneficiaryModal",
                                $$v
                              )
                            },
                            expression: "events.showNewBeneficiaryModal"
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-lg-12" },
                              [
                                _c("b-form", [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6" },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Nom",
                                              "label-for":
                                                "formrow-firstname-input"
                                            }
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "formrow-firstname-input",
                                                type: "text"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6" },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Prénom",
                                              "label-for":
                                                "formrow-firstname-input"
                                            }
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "formrow-firstname-input",
                                                type: "text"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6" },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Email",
                                              "label-for":
                                                "formrow-firstname-input"
                                            }
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "formrow-firstname-input",
                                                type: "text"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6" },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Téléphone",
                                              "label-for":
                                                "formrow-firstname-input"
                                            }
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "formrow-firstname-input",
                                                type: "text"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm.bookingForm.beneficiaryType.id !=
                                  "visitor"
                                    ? _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-6" },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: "N° de badge",
                                                  "label-for":
                                                    "formrow-firstname-input"
                                                }
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id:
                                                      "formrow-firstname-input",
                                                    type: "text"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.bookingForm.beneficiaryType.id ==
                                  "visitor"
                                    ? _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-6" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formrow-inputCity"
                                                }
                                              },
                                              [_vm._v("Entreprise *")]
                                            ),
                                            _c("multiselect", {
                                              class: {
                                                "is-invalid":
                                                  _vm.submitted &&
                                                  _vm.$v.bookingForm
                                                    .beneficiaryType.$error
                                              },
                                              attrs: {
                                                searchable: true,
                                                "track-by": "id",
                                                "open-direction": "bottom",
                                                label: "name",
                                                options: _vm.beneficiaryTypes,
                                                placeholder: "",
                                                "allow-empty": false
                                              },
                                              on: {
                                                input:
                                                  _vm.onBeneficiaryTypeChange
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "singleRoom",
                                                    fn: function(ref) {
                                                      var type = ref.type
                                                      return [
                                                        _vm._v(
                                                          _vm._s(type.name)
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                890565942
                                              ),
                                              model: {
                                                value:
                                                  _vm.bookingForm
                                                    .beneficiaryType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.bookingForm,
                                                    "beneficiaryType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "bookingForm.beneficiaryType"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-lg-3" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "resume" } },
                                              [_vm._v("Pièce d'identité")]
                                            ),
                                            _c("multiselect", {
                                              attrs: {
                                                searchable: true,
                                                "track-by": "id",
                                                label: "name",
                                                options: _vm.identityDocTypes,
                                                placeholder: "Selectionner",
                                                "allow-empty": false
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "singleMealType",
                                                    fn: function(ref) {
                                                      var type = ref.type
                                                      return [
                                                        _vm._v(
                                                          _vm._s(type.name)
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2867290996
                                              ),
                                              model: {
                                                value:
                                                  _vm.newBeneficiaryModal
                                                    .identityDocType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.newBeneficiaryModal,
                                                    "identityDocType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newBeneficiaryModal.identityDocType"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "col-lg-3" }, [
                                          _c(
                                            "label",
                                            { attrs: { for: "subject" } },
                                            [_vm._v("N° Identité")]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.newBeneficiaryModal
                                                    .identityDocNo,
                                                expression:
                                                  "newBeneficiaryModal.identityDocNo"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "subject",
                                              type: "text"
                                            },
                                            domProps: {
                                              value:
                                                _vm.newBeneficiaryModal
                                                  .identityDocNo
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.newBeneficiaryModal,
                                                  "identityDocNo",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ])
                                    : _vm._e(),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: { variant: "primary" },
                                            on: {
                                              click: _vm.saveNewBenefiaryModal
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-save"
                                            }),
                                            _vm._v(" Enregistrer")
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "Modal",
                        {
                          attrs: {
                            title:
                              "Délogement:  " +
                              _vm.bookingForm.beneficiaryId.fullNameUc
                          },
                          model: {
                            value: _vm.events.showRelocationModal,
                            callback: function($$v) {
                              _vm.$set(_vm.events, "showRelocationModal", $$v)
                            },
                            expression: "events.showRelocationModal"
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Occupant")
                              ]),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: { type: "text", disabled: "" },
                                domProps: {
                                  value:
                                    _vm.bookingForm.beneficiaryId.fullNameUc
                                }
                              })
                            ]),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Chambre Actuelle")
                              ]),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: { type: "text", disabled: "" },
                                domProps: {
                                  value:
                                    "Bloc: " +
                                    _vm.bookingForm.room.blocName +
                                    "- N° " +
                                    _vm.bookingForm.room.number
                                }
                              })
                            ]),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "formrow-inputCity" } },
                                    [_vm._v("Site *")]
                                  ),
                                  _c("multiselect", {
                                    attrs: {
                                      searchable: true,
                                      "track-by": "id",
                                      label: "name",
                                      options: _vm.camps,
                                      placeholder: "Selectionner le site",
                                      "allow-empty": false
                                    },
                                    on: {
                                      select: function($event) {
                                        _vm.relocationModal.blocs =
                                          $event.buildings
                                      }
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "singleCamp",
                                        fn: function(ref) {
                                          var camp = ref.camp
                                          return [_vm._v(_vm._s(camp.name))]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.relocationModal.campId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.relocationModal,
                                          "campId",
                                          $$v
                                        )
                                      },
                                      expression: "relocationModal.campId"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-md-4 mt-1" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Bloc / Secteur")
                                ]),
                                _c("multiselect", {
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    "open-direction": "bottom",
                                    label: "name",
                                    options: _vm.relocationModal.blocs,
                                    placeholder: "",
                                    "allow-empty": false
                                  },
                                  on: { input: _vm.onRelocationCampChange },
                                  model: {
                                    value: _vm.relocationModal.blocId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.relocationModal,
                                        "blocId",
                                        $$v
                                      )
                                    },
                                    expression: "relocationModal.blocId"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4 mt-1" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Nouvelle chambre")
                                ]),
                                _c("multiselect", {
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    "open-direction": "bottom",
                                    label: "number",
                                    options:
                                      _vm.relocationModal.alternativeRooms,
                                    placeholder: "",
                                    "allow-empty": false
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "singleRoom",
                                      fn: function(ref) {
                                        var room = ref.room
                                        return [_vm._v(_vm._s(room.roomName))]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.relocationModal.newRoomId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.relocationModal,
                                        "newRoomId",
                                        $$v
                                      )
                                    },
                                    expression: "relocationModal.newRoomId"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-12" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Motif")
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.relocationModal.comments,
                                    expression: "relocationModal.comments"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { cols: "30", rows: "10" },
                                domProps: {
                                  value: _vm.relocationModal.comments
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.relocationModal,
                                      "comments",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "col-md-12 mt-2" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary float-right",
                                  on: { click: _vm.saveRelocation }
                                },
                                [
                                  _c("i", { staticClass: "fas fa-save" }),
                                  _vm._v(" Enregistrer ")
                                ]
                              )
                            ])
                          ])
                        ]
                      ),
                      _c(
                        "Modal",
                        {
                          attrs: {
                            title:
                              "Prolongation:  " +
                              _vm.bookingForm.beneficiaryId.fullNameUc
                          },
                          model: {
                            value: _vm.events.showProlongationModal,
                            callback: function($$v) {
                              _vm.$set(_vm.events, "showProlongationModal", $$v)
                            },
                            expression: "events.showProlongationModal"
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Occupant")
                              ]),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: { type: "text", disabled: "" },
                                domProps: {
                                  value:
                                    _vm.bookingForm.beneficiaryId.fullNameUc
                                }
                              })
                            ]),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Chambre Actuelle")
                              ]),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: { type: "text", disabled: "" },
                                domProps: {
                                  value:
                                    "Bloc: " +
                                    _vm.bookingForm.room.blocName +
                                    "- N° " +
                                    _vm.bookingForm.room.number
                                }
                              })
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Prolonger Du:")
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.prolongationModal.from,
                                    expression: "prolongationModal.from"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "date" },
                                domProps: { value: _vm.prolongationModal.from },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.prolongationModal,
                                      "from",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "col" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Prolonger Au:")
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.prolongationModal.to,
                                    expression: "prolongationModal.to"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "date" },
                                domProps: { value: _vm.prolongationModal.to },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.prolongationModal,
                                      "to",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-12" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Commentaires")
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.prolongationModal.comments,
                                    expression: "prolongationModal.comments"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { cols: "30", rows: "2" },
                                domProps: {
                                  value: _vm.prolongationModal.comments
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.prolongationModal,
                                      "comments",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("div", { staticClass: "col-md-12 mt-2" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary float-right",
                                  on: { click: _vm.saveProlongation }
                                },
                                [
                                  _c("i", { staticClass: "fas fa-save" }),
                                  _vm._v(" Enregistrer ")
                                ]
                              )
                            ])
                          ])
                        ]
                      ),
                      _c(
                        "Modal",
                        {
                          attrs: {
                            modalClass: "checkInModal fullWidthModal",
                            title: "EEEE"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "titlebar",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "vm-titlebar" }, [
                                    _c("h3", { staticClass: "vm-title" }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/base/ops/hosting/check_in.png"),
                                          alt: ""
                                        }
                                      }),
                                      _c("p", [
                                        _vm._v(
                                          " Check In Pour la réservation N°: "
                                        ),
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.bookingForm.ref))
                                        ])
                                      ])
                                    ])
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.events.showCheckInModal,
                            callback: function($$v) {
                              _vm.$set(_vm.events, "showCheckInModal", $$v)
                            },
                            expression: "events.showCheckInModal"
                          }
                        },
                        [
                          _c("div", {}, [
                            _c(
                              "div",
                              { staticClass: "row bookingDataContainer" },
                              [
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Référence")
                                  ]),
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.bookingForm.ref))
                                    ])
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Date d'arrivé")
                                  ]),
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.bookingForm.dateIn))
                                    ])
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Date de départ")
                                  ]),
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.bookingForm.dateOut))
                                    ]),
                                    _vm._v(
                                      " (" +
                                        _vm._s(_vm.bookingForm.nights) +
                                        " Nuitées) "
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Occupant")
                                  ]),
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.bookingForm.beneficiaryId
                                            .fullNameUc
                                        )
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Chambre Affectée")
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c("multiselect", {
                                      class: {
                                        "is-invalid":
                                          _vm.submitted &&
                                          _vm.$v.checkInModal.roomId.$error
                                      },
                                      attrs: {
                                        searchable: true,
                                        "track-by": "id",
                                        "open-direction": "bottom",
                                        label: "roomName",
                                        options: _vm.rooms,
                                        placeholder: "",
                                        "allow-empty": false
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "singleRoom",
                                          fn: function(ref) {
                                            var room = ref.room
                                            return [
                                              _vm._v(_vm._s(room.roomName))
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.checkInModal.roomId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.checkInModal,
                                            "roomId",
                                            $$v
                                          )
                                        },
                                        expression: "checkInModal.roomId"
                                      }
                                    }),
                                    _vm.submitted &&
                                    _vm.$v.checkInModal.roomId.$error
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            !_vm.$v.checkInModal.roomId.required
                                              ? _c("span", [
                                                  _vm._v("Champ Obligatoire.")
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Opération effectuée par:")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "a",
                                    { attrs: { href: "javascript:void(0)" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.user.first_name) +
                                          " " +
                                          _vm._s(_vm.user.last_name)
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Date / heure d'opération:")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c("p", [
                                    _c("b", [_vm._v(_vm._s(_vm.now()))])
                                  ])
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("label", { attrs: { for: "subject" } }, [
                                  _vm._v("Commentaires")
                                ]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkInModal.comments,
                                      expression: "checkInModal.comments"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { cols: "30", rows: "4" },
                                  domProps: {
                                    value: _vm.checkInModal.comments
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.checkInModal,
                                        "comments",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _c("br"),
                            _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: { click: _vm.performCheckin }
                                },
                                [
                                  _c("i", { staticClass: "fas fa-save" }),
                                  _vm._v(" Enregistrer ")
                                ]
                              )
                            ])
                          ])
                        ]
                      ),
                      _c(
                        "Modal",
                        {
                          attrs: { modalClass: "checkInModal fullWidthModal" },
                          scopedSlots: _vm._u([
                            {
                              key: "titlebar",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "vm-titlebar" }, [
                                    _c("h3", { staticClass: "vm-title" }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/base/ops/hosting/check_out.png"),
                                          alt: ""
                                        }
                                      }),
                                      _c("p", [
                                        _vm._v(
                                          " Check Out Pour la réservation N°: "
                                        ),
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.bookingForm.ref))
                                        ])
                                      ])
                                    ])
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.events.showCheckOutModal,
                            callback: function($$v) {
                              _vm.$set(_vm.events, "showCheckOutModal", $$v)
                            },
                            expression: "events.showCheckOutModal"
                          }
                        },
                        [
                          _c("div", {}, [
                            _c(
                              "div",
                              { staticClass: "row bookingDataContainer" },
                              [
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Référence")
                                  ]),
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.bookingForm.ref))
                                    ])
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Date d'arrivé")
                                  ]),
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.bookingForm.dateIn))
                                    ])
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Date de départ")
                                  ]),
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.bookingForm.dateOut))
                                    ]),
                                    _vm._v(
                                      " (" +
                                        _vm._s(_vm.bookingForm.nights) +
                                        " Nuitées) "
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Occupant")
                                  ]),
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.bookingForm.beneficiaryId
                                            .fullNameUc
                                        )
                                      )
                                    ])
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-3" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Chambre Affectée")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c("p", [
                                    _vm._v(" Chambre N°: "),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(_vm.bookingForm.room.number)
                                      )
                                    ])
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Opération effectuée par:")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "a",
                                    { attrs: { href: "javascript:void(0)" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.user.first_name) +
                                          " " +
                                          _vm._s(_vm.user.last_name)
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Date / heure d'opération:")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c("p", [
                                    _c("b", [_vm._v(_vm._s(_vm.now()))])
                                  ])
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c("label", { attrs: { for: "subject" } }, [
                                    _vm._v("Clé remis")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c("b-form-checkbox", {
                                    staticClass: "mb-1",
                                    staticStyle: { display: "inline-block" },
                                    attrs: { switch: "" },
                                    model: {
                                      value: _vm.checkOutModal.keyReturned,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.checkOutModal,
                                          "keyReturned",
                                          $$v
                                        )
                                      },
                                      expression: "checkOutModal.keyReturned"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("label", { attrs: { for: "subject" } }, [
                                  _vm._v("Commentaires")
                                ]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkOutModal.comments,
                                      expression: "checkOutModal.comments"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { cols: "30", rows: "4" },
                                  domProps: {
                                    value: _vm.checkOutModal.comments
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.checkOutModal,
                                        "comments",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _c("br"),
                            _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: { click: _vm.performCheckout }
                                },
                                [
                                  _c("i", { staticClass: "fas fa-save" }),
                                  _vm._v(" Enregistrer ")
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("approval-modal", {
                    attrs: {
                      modelRef: _vm.bookingForm.ref,
                      modelId: _vm.bookingForm.id,
                      modelType: _vm.bookingForm.modelType
                    }
                  }),
                  _c("rejection-modal", {
                    attrs: {
                      modelRef: _vm.bookingForm.ref,
                      modelId: _vm.bookingForm.id,
                      modelType: _vm.bookingForm.modelType
                    }
                  })
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            { staticClass: "d-inline-block d-sm-none" },
                            [_c("i", { staticClass: "far fa-user" })]
                          ),
                          _c(
                            "span",
                            { staticClass: "d-none d-sm-inline-block" },
                            [_vm._v("Check-IN")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "table-responsive mb-0" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("Date:")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.bookingForm.checkin_at))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Opération par::")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.bookingForm.checkedInBy))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Commentaires:")]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.bookingForm.checkInComments) +
                                    " "
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            { staticClass: "d-inline-block d-sm-none" },
                            [_c("i", { staticClass: "far fa-envelope" })]
                          ),
                          _c(
                            "span",
                            { staticClass: "d-none d-sm-inline-block" },
                            [_vm._v("Check-OUT")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "table-responsive mb-0" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("Date:")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.bookingForm.checkout_at))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Opération par::")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.bookingForm.checkedOutBy))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Commentaires:")]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.bookingForm.checkOutComments) +
                                    " "
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Remise de clé:")]),
                              _c("td", [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.bookingForm.keyStatus)
                                  }
                                })
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            { staticClass: "d-inline-block d-sm-none" },
                            [_c("i", { staticClass: "far fa-user" })]
                          ),
                          _c(
                            "span",
                            { staticClass: "d-none d-sm-inline-block" },
                            [
                              _vm._v("Délogement "),
                              _vm.singleBookingData
                                ? _c(
                                    "span",
                                    { staticClass: "badge badge-info" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.singleBookingData.relocations
                                              .length
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm.singleBookingData
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _vm.$can("create_relocation") &&
                          _vm.bookingForm.ref &&
                          (_vm.bookingForm.statusPlain == "approved" ||
                            _vm.bookingForm.statusPlain == "checkin")
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: { click: _vm.showRelocationModalClick }
                                },
                                [_vm._v(" Ajouter ")]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table mb-0" }, [
                              _c("thead", { staticClass: "table-light" }, [
                                _c("tr", [
                                  _c("th", [_vm._v("ID")]),
                                  _c("th", [_vm._v("Date")]),
                                  _c("th", [_vm._v("Site")]),
                                  _c("th", [_vm._v("Bloc/Secteur")]),
                                  _c("th", [_vm._v("Chambre")]),
                                  _c("th", [_vm._v("Ancien Site")]),
                                  _c("th", [_vm._v("Ancien Bloc/Secteur")]),
                                  _c("th", [_vm._v("Ancienne Chambre")]),
                                  _c("th", [_vm._v("Par")])
                                ])
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.singleBookingData.relocations,
                                  function(relocation, index) {
                                    return _c("tr", { key: index }, [
                                      _c("th", { attrs: { scope: "row" } }, [
                                        _vm._v(_vm._s(index + 1))
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(relocation.date))
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          { staticClass: "badge bg-info ms-1" },
                                          [_vm._v(_vm._s(relocation.camp.name))]
                                        )
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "badge bg-primary ms-1"
                                          },
                                          [_vm._v(_vm._s(relocation.bloc.name))]
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(relocation.room.roomName))
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          { staticClass: "badge bg-info ms-1" },
                                          [
                                            _vm._v(
                                              _vm._s(relocation.camp_from.name)
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "badge bg-primary ms-1"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(relocation.bloc_from.name)
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge bg-secondary ms-1"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                relocation.room_from.roomName
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            relocation.by.first_name +
                                              " " +
                                              relocation.by.last_name
                                          )
                                        )
                                      ])
                                    ])
                                  }
                                ),
                                0
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            { staticClass: "d-inline-block d-sm-none" },
                            [_c("i", { staticClass: "far fa-user" })]
                          ),
                          _c(
                            "span",
                            { staticClass: "d-none d-sm-inline-block" },
                            [
                              _vm._v("Prolongement "),
                              _vm.singleBookingData
                                ? _c(
                                    "span",
                                    { staticClass: "badge badge-info" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.singleBookingData.prolongations
                                              .length
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm.singleBookingData
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _vm.$can("create_relocation") &&
                          _vm.bookingForm.ref &&
                          (_vm.bookingForm.statusPlain == "approved" ||
                            _vm.bookingForm.statusPlain == "checkin")
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function($event) {
                                      _vm.events.showProlongationModal = true
                                    }
                                  }
                                },
                                [_vm._v(" Ajouter ")]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table mb-0" }, [
                              _c("thead", { staticClass: "table-light" }, [
                                _c("tr", [
                                  _c("th", [_vm._v("ID")]),
                                  _c("th", [_vm._v("Date début réservation")]),
                                  _c("th", [_vm._v("Date fin réservation")]),
                                  _c("th", [_vm._v("Date début Prolongement")]),
                                  _c("th", [_vm._v("Date Fin Prolongement")]),
                                  _c("th", [_vm._v("Ajoutée le")]),
                                  _c("th", [_vm._v("Par")])
                                ])
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.singleBookingData.prolongations,
                                  function(relocation, index) {
                                    return _c("tr", { key: index }, [
                                      _c("th", { attrs: { scope: "row" } }, [
                                        _vm._v(_vm._s(index + 1))
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge bg-secondary ms-1"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                relocation.booking_start_format
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge bg-secondary ms-1"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                relocation.booking_end_format
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          { staticClass: "badge bg-info ms-1" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                relocation.prolong_start_format
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "badge bg-primary ms-1"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                relocation.prolong_end_format
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(relocation.date))
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            relocation.by.first_name +
                                              " " +
                                              relocation.by.last_name
                                          )
                                        )
                                      ])
                                    ])
                                  }
                                ),
                                0
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }