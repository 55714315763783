<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import BookingsList from "./components/BookingsList";
import BookingFormBeta from "./components/BookingFormBeta";
import GuiAvailabilityModal from "./components/common/GuiAvailabilityModal";
import SideButtons from "../../../../../components/side-buttons.vue";
import Swal from "sweetalert2";

import { fetchLifebasesApi } from "@/api/common";
// import EmployeesPlanning from '../components/employeesPlanning.vue';
export default {
  page: {
    title: "Réservations",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    BookingsList,
    BookingFormBeta,
    GuiAvailabilityModal,
    SideButtons,
    // EmployeesPlanning
  },
  data() {
    return {
      title: "Réservation",
      resetAddBtn: false,
      showRoomsAvailabilityModal: false,
      shouldRefreshBookingsTable: false,
      singleBookingData: null,
      bookings_SearchResult: [],
      refresh:false,
      counts: {
        approved: 0,
        rejected: 0,
        requests: 0,
        checkIn: 0,
        checkOut: 0,
        closed: 0,
      },
      date: "",
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
      bases: [],
      events: {
        disableForm: true,
        shouldClearAllForms: false,
      },
      prefilledRoomAvailability: {
        from: "",
        to: "",
        user: "",
        booking: "",
      },
      items: [
        {
          text: "Hébergement",
          href: "javascript:void(0)",
        },
        {
          text: "Réservations",
          active: true,
        },
      ],
    };
  },

  // watch: {
  //  shouldRefreshBookingsTable(){
  //    if(this.shouldRefreshBookingsTable){

  //    }
  //  }
  // },

  mounted() {
    this._refreshButtonsCount();
    this._fetchCampsList();
    // this.shouldRefreshBookingsTable = true;
  },

  methods: {
    searchBookings() {
      var loader = this.$loading.show();
      var selectedCamps = "";
      this.$http
        .post("/ops/booking/searchBookings", {
          from: this.date.start,
          to: this.date.end,
          camps: this.bases,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var list = res.data.list;
          switch (status) {
            case 200:
              this.bookings_SearchResult = list;
              break;

            case 500:
              loader.hide();

              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    _fetchCampsList() {
      fetchLifebasesApi()
        .then((res) => (this.bases = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
      // bases
    },
    showRoomsAvailabilityModalClick() {
      this.showRoomsAvailabilityModal = true;
    },

    handleRoomsAvailabilityForSingleRequestEvent(data) {
      this.prefilledRoomAvailability.from = data.planned_arrival_date;
      this.prefilledRoomAvailability.to = data.planned_departure_date;
      this.showRoomsAvailabilityModal = true;
    },

    handleShowSingleBookingDataEvent(booking) {
      this.singleBookingData = booking;
    },
    _refreshButtonsCount() {
      // call api to get new counts
      this.$http
        .post("/ops/booking/getBookingStatus")
        .then((res) => {
          var status = res.data.status;
          var result = res.data.counts;
          switch (status) {
            case 200:
              this.counts.requests = result.requests;
              this.counts.checkIn = result.checkIn;
              this.counts.checkOut = result.checkOut;
              this.counts.approved = result.approved;
              this.counts.rejected = result.rejected;
              this.counts.closed = result.closed;
              break;

            case 500:
              this.$toast.warning(res.data.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    filterBookings(stat) {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/booking/filterBookings", {
          stat: stat,
        })
        .then((res) => {
          loader.hide();
          this.bookings_SearchResult = res.data;
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    handleRefreshButtonsCountEvent() {
      this._refreshButtonsCount();
    },
    handleBookingStoredEvent() {
      this._refreshButtonsCount();
      this.shouldRefreshBookingsTable = true;
      this.resetAddBtn = true;
    },
    handleFreeAddBtnEvent() {
      this.events.disableForm = true;
      // this.events.shouldClearAllForms = true;
      this.resetAddBtn = true;
    },

    addBookingClickEvent() {
      this.events.disableForm = false;
      this.events.shouldClearAllForms = true;
    },

    editBookingClickEvent(event) {
      if (event) {
        var allowedStatusToBeEdited = ["draft", "approved"];
        console.log(allowedStatusToBeEdited.includes(this.singleBookingData.statusPlain))
        if (this.singleBookingData) {
          if (
            allowedStatusToBeEdited.includes(this.singleBookingData.statusPlain)
          ) {
            this.events.disableForm = false;
            this.events.shouldClearAllForms = false;
          } else {
            this.$toast.error("Impossible de modifier cette réservation.");
          }
        } else {
          this.$toast.error("Vous devez sélectionner au moin une réservation");
        }
      }
    },

    deleteBookingClickEvent(event) {
      if (event) {
        if (this.singleBookingData) {
          if (this.singleBookingData.statusPlain == "draft") {
            Swal.fire({
              title:
                "Êtes-vous sûr de supprimer la réservation N°: " +
                this.singleBookingData.ref +
                "?",
              text: "Vous ne pourrez pas annuler cela!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Supprimer!",
            }).then((result) => {
              if (result.value) {
                var loader = this.$loading.show();
                this.$http
                  .post("/ops/booking/delete/" + this.singleBookingData.uuid)
                  .then((res) => {
                    var status = res.data.original.status;
                    loader.hide();
                    switch (status) {
                      case 200:
                        this.$toast.success(res.data.original.msg);
                        this._refreshButtonsCount();
                        this.shouldRefreshBookingsTable = true;
                        this.events.shouldClearAllForms = true;
                        break;

                      case 500:
                        this.$toast.warning(res.data.original.msg);
                        break;
                    }
                  })
                  .catch((error) => {
                    loader.hide();
                    this.$toast.error(error.message);
                  })
                  .finally(() => {});
              }
            });
          } else {
            this.$toast.error("Impossible de supprimer cette réservation.");
          }
        } else {
          this.$toast.error("Vous devez sélectionner au moin une réservation.");
        }
      }
    },

    // emits
    handleCheckInPerformedEvent() {
      this._refreshButtonsCount();
      this.shouldRefreshBookingsTable = true;
    },
    handleCheckOutPerformedEvent() {
      this._refreshButtonsCount();
      this.shouldRefreshBookingsTable = true;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader>
      <template v-slot:datePicker>
        <b-button
          variant="outline-primary"
          class="ml-2"
          @click="showRoomsAvailabilityModalClick"
        >
          Disponibilité
        </b-button>
      </template>
      <template v-slot:buttons>
        <div>
          <div class="row">
            <div class="col-md-12">
              <div class="button-items hosting-actions">
                <b-button
                  variant="outline-primary"
                  @click="filterBookings('requests')"
                >
                  Demandes
                  <span
                    class="badge"
                    :class="
                      counts.requests > 0 ? 'badge-warning' : 'badge-success'
                    "
                    >{{ counts.requests }}</span
                  >
                </b-button>

                <b-button
                  variant="outline-primary"
                  @click="filterBookings('approved')"
                >
                  Approuvé
                  <span
                    class="badge"
                    :class="
                      counts.approved > 0 ? 'badge-warning' : 'badge-success'
                    "
                    >{{ counts.approved }}</span
                  >
                </b-button>

                <b-button
                  variant="outline-primary"
                  @click="filterBookings('rejected')"
                >
                  Rejeté
                  <span
                    class="badge"
                    :class="
                      counts.rejected > 0 ? 'badge-warning' : 'badge-success'
                    "
                    >{{ counts.rejected }}</span
                  >
                </b-button>

                <b-button
                  variant="outline-primary"
                  @click="filterBookings('closed')"
                >
                  Annulé
                  <span
                    class="badge"
                    :class="
                      counts.closed > 0 ? 'badge-warning' : 'badge-success'
                    "
                    >{{ counts.closed }}</span
                  >
                </b-button>

                <b-button
                  variant="outline-primary"
                  @click="filterBookings('checkin_today')"
                >
                  Check IN
                  <span
                    class="badge"
                    :class="
                      counts.checkIn > 0 ? 'badge-warning' : 'badge-success'
                    "
                    >{{ counts.checkIn }}</span
                  >
                </b-button>
                <b-button
                  variant="outline-primary"
                  @click="filterBookings('checkout_today')"
                >
                  Check OUT
                  <span
                    class="badge"
                    :class="
                      counts.checkOut > 0 ? 'badge-warning' : 'badge-success'
                    "
                    >{{ counts.checkOut }}</span
                  >
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </PageHeader>
    <SideButtons
      :reset="resetAddBtn"
      :save="items"
      @info="items"
      @add="addBookingClickEvent"
      @save="items"
      @edit="editBookingClickEvent"
      @confirm="items"
      @validate="items"
      @delete="deleteBookingClickEvent"
    />
    <gui-availability-modal
      @closeAvailabilityModal="showRoomsAvailabilityModal = $event"
      :show="showRoomsAvailabilityModal"
      :from="prefilledRoomAvailability.from"
      :to="prefilledRoomAvailability.to"
      :camps="bases"
    />
    <!-- <booking-shortcuts/> -->
    <bookings-list
      @showRoomsAvailabilityForRequest="handleRoomsAvailabilityForSingleRequestEvent"
      @showSingleBookingData="handleShowSingleBookingDataEvent"
      :refreshBookings="shouldRefreshBookingsTable"
      :searchResult="bookings_SearchResult"
      @refreshOK="shouldRefreshBookingsTable = $event"
    />
    <booking-form-beta
      :formDisabled="events.disableForm"
      :singleBookingData="singleBookingData"
      :shouldClearAllForms="events.shouldClearAllForms"
      @checkOutPerformed="handleCheckOutPerformedEvent"
      @checkInPerformed="handleCheckInPerformedEvent"
      @bookingStored="handleBookingStoredEvent"
      @freeAddBtn="handleFreeAddBtnEvent"
      @refreshButtonsCount="handleRefreshButtonsCountEvent"
      @refresh="shouldRefreshBookingsTable = $event"
    />
  </Layout>
</template>
